import React, { Component } from 'react';
import './index.css';

class Circle extends Component {
  constructor() {
    super(...arguments);
    let radius = 38;
    let circumference = 2 * Math.PI * radius;
    this.state = {
      transitionDuration: this.props.transitionDuration || 800,
      percent: this.props.percent || 6.66,
      percentInt: "0.",
      percentDec: "00",
      x: 41,
      y: 41,
      r: radius,
      initialStroke: 0,
      strokeDashoffset: circumference
    };
  }

  componentDidMount() {
    this.animate();
  }
  componentWillUnmount() {

  }
  animate = () => {
    let note = parseFloat(this.state.percent);
    let [int, dec] = this.state.percent.split('.');
    [int, dec] = [Number(int), Number(dec)];

    this.strokeTransition(note);

    this.increaseNumber(int, 'percentInt');
    this.increaseNumber(dec, 'percentDec');
  }

  strokeTransition = (note) => {
    let radius = this.state.r;
    let circumference = 2 * Math.PI * radius;
    let offset = circumference * (10 - note) / 10;
    this.setState({
      initialStroke: circumference,
    });
    setTimeout(() => {
      this.setState({
        strokeDashoffset: offset
      })
    }, 50);
  }

  increaseNumber = (number, type) => {
    let decPoint = type === 'percentInt' ? '.' : '',
      interval = this.state.transitionDuration / number,
      counter = 0;

    let increaseInterval = setInterval(() => {
      if (counter === number) {
        clearInterval(increaseInterval);
      }
      this.setState({
        [type]: counter + decPoint
      })
      counter++;
    }, interval);
  }

  render() {
    return (
      <div>
        <div className="circle">
          <svg width="84" height="84" className="circle__svg">
            <circle cx={this.state.x} cy={this.state.y} r={this.state.r}
              className="circle__progress circle__progress--path"
              style={{ stroke: this.props.progressColor }}>
            </circle>
            <circle cx={this.state.x} cy={this.state.y} r={this.state.r}
              className="circle__progress circle__progress--fill"
              style={{
                stroke: this.props.progressColor,
                "stroke-dasharray": this.state.initialStroke,
                "stroke-dashoffset": this.state.strokeDashoffset,
                "transition-property": "stroke-dashoffset",
                "transition-duration": `${this.state.transitionDuration}ms`,
                "transition-timing-function": "ease"
              }}>
            </circle>
          </svg>
          <div className="percent">
            <span className="percent__int">{this.state.percentInt}</span>
            <span className="percent__dec">{this.state.percentDec}</span>
          </div>
        </div>
        <span className="label">{this.props.label}</span>
      </div >
    )
  }
}

export default Circle;