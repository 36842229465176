import React, { Component } from 'react';
import logo from './assets/logo.png';
import './App.css';
import Circle from './components/circle';

class App extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      start: 0
    };
  }
  render() {
    return (
      <div className="App">
        <header className="App-header ">
          <div>
            <img src={logo} className="App-logo" alt="logo" />
            <Circle label="oss.adamsandwich.com" progressColor="#AAFF00" percent="6.66" />
          </div>
        </header>
      </div>
    );
  }
}

export default App;